export function GetRaceAgeGroup(EventName) {
  if (EventName.includes("25")) {
    return "U25";
  }
  if (EventName.includes("21")) {
    return "U21";
  }
  if (EventName.includes("18")) {
    return "U18";
  }
  if (EventName.includes("15")) {
    return "U15";
  }
}

export function GetRaceGender(EventName) {
  if (EventName.includes("Boys")) {
    return "Male";
  }
  if (EventName.includes("Girls")) {
    return "Female";
  }
}

export function GetRaceClass(EventName) {
  if (
    EventName.includes("Scull") ||
    EventName.includes("Pair") ||
    EventName.includes("Coxed")
  ) {
    return "Water Race";
  }
  if (
    !EventName.includes("Scull") &&
    !EventName.includes("Pair") &&
    !EventName.includes("Coxed")
  ) {
    return "Indoor Race";
  }
}

export function GetDefaultRaceDuration(racename) {
  if (racename.includes("4 x 500m")) {
    return 15;
  }
  if (racename.includes("2 x 1000m")) {
    return 10;
  }
  if (racename.includes("5000m")) {
    return 30;
  }
  if (racename.includes("2000m")) {
    return 15;
  }
  if (racename.includes("500m")) {
    return 10;
  }
  if (
    racename.includes("Heat") ||
    racename.includes("Semi") ||
    racename.includes("Quarter")
  ) {
    return 10;
  }
  if (racename.includes("Final")) {
    return 15;
  }
  if (racename.includes("Repecharge")) {
    return 15;
  }
}

export function GetIndoorRaceSplitDetails(raceName) {
  // Define race types and their respective splits and total distances
  const racePatterns = [
    { regex: /1 x (\d+)m/, type: "Individual", splits: 1 },
    { regex: /2 x (\d+)m Relay/, type: "Relay", splits: 2 },
    { regex: /4 x (\d+)m Relay/, type: "Relay", splits: 4 },
  ];

  // Iterate through race patterns to find a match
  for (const pattern of racePatterns) {
    const match = raceName.match(pattern.regex);
    if (match) {
      const splitDistance = parseInt(match[1], 10); // Extract the distance
      const totalDistance = splitDistance * pattern.splits; // Calculate total distance
      return {
        type: pattern.type,
        splits: pattern.splits,
        splitDistance,
        totalDistance,
      };
    }
  }

  // Return null if no race type matches
  return null;
}

import React, { useState } from "react";
import { Button, Dropdown, Menu, Checkbox } from "antd";

const CheckboxDropdown = ({
  options = [],
  selectedOptions = [],
  buttonLabel = "Select Options",
  onSelectionChange,
  dropdownPlacement = "bottomLeft",
}) => {
  const [visible, setVisible] = useState(false);

  const handleCheckboxChange = (option, checked) => {
    const newSelectedOptions = checked
      ? [...selectedOptions, option]
      : selectedOptions.filter((item) => item !== option);

    // Notify parent component of the changes
    if (onSelectionChange) {
      onSelectionChange(newSelectedOptions);
    }
  };

  const menu = (
    <Menu>
      {options.map((option) => (
        <Menu.Item key={option}>
          <Checkbox
            onChange={(e) => handleCheckboxChange(option, e.target.checked)}
            checked={selectedOptions.includes(option)}
          >
            {option}
          </Checkbox>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement={dropdownPlacement}
      visible={visible}
      onVisibleChange={(flag) => setVisible(flag)}
    >
      <Button
        onClick={() => {
          setVisible(!visible);
        }}
      >
        {buttonLabel}
      </Button>
    </Dropdown>
  );
};

export default CheckboxDropdown;
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { Layout } from "antd";

import { ProtectedRoute } from "../../ProtectedRoute";

import styles from "./MainLayout.module.css";
import SideBar from "./SideBar";
//Pages
import HomePage from "../HomePage/HomePage";
import Schools from "../Schools/Schools";
import Status from "../Status/Status";
import ReportsPage from "../Reports/Reports";
import PaymentsPage from "../Payments/PaymentsPage";
import NewPaymentsPage from "../Payments/NewPaymentsPage";
import FlagPage from "../Flag/NewFlagPage";
import AddSchoolInfoPage from "../AddSchool/AddSchoolInfoPage";
import UserManagement from "../UserManagement/UserManagement";
import SetupNewEvent from "../RaceControl/SetupNewOutdoorEventNew";
import RacesSchedule from "../RacesSchedule/RacesSchedule";

import SetupNewIndoorEvent from "../RaceControl/SetupNewIndoorEvent";
import ISROSDashboard from "../ISROS Dashboard/ISROSDashboard";
import Substitutions from "../Substitutions/Substitutions";
import CoxWeight from "../CoxWeight/CoxWeight";
import PenaltyCardsPage from "../PenaltyCardsPage/PenaltyCardsPage";
import IndoorResults from "../IndoorResults/IndoorResults";
import IndoorRecordsPage from "../IndoorRecords/IndoorRecordsPage";
import DisqualificationsPage from "../DisqualificationsPage/DisqualificationsPage";
import LeaderboardPage from "../LeaderboardPage/LeaderboardPage";
import CommentryPage from "../CommentryPage/CommentryPage";

import CreateIDCardTemplate from "../IDCards/CreateIDCardTemplate";
import ControlPanelPage from "../ControlPanelPage/ControlPanelPage";
import RaceDefinitionForm from "../RAC2Editor/RAC2Editor";
import RaceDetails from "../RAC2Editor/RaceResultsEditor";

import OldCollections from "../OldCollections/OldCollections";
import BackupAndRestorePage from "../BackupRestore/BackupAndRestore";

import DownloadRaceFile from "../ErgRace/DownloadRaceFile";

import IDCardManagementPage from "../IDCards/IDCardManagement";

import Test from "../Test/Test";

import AppHeader from "./AppHeader";
import AnnouncementsBox from "../AnnouncementsBox/AnnouncementsBox";

const { Header, Content, Footer, Sider } = Layout;
const MainLayout = () => {
  const location = useLocation();
  const history = useHistory();
  return (
    <div>
      <Layout className={styles.MainLayout}>
        <SideBar />
        <Layout className={styles.siteLayout}>
          <Header
            className={styles.siteLayoutBackground}
            style={{ padding: 0 }}
          >
            <AppHeader />
          </Header>
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            <div
              className={styles.siteLayoutBackground}
              style={{ padding: 24 }}
            >
              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  Component={HomePage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/schools"
                  Component={Schools}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/status"
                  Component={Status}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/reports"
                  Component={ReportsPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/Payments"
                  Component={NewPaymentsPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/NewPayments"
                  Component={NewPaymentsPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/Flags"
                  Component={FlagPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/AddNewSchool"
                  Component={AddSchoolInfoPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/UserManagement"
                  Component={UserManagement}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/Test"
                  Component={Test}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/SetupOutDoorEvent"
                  Component={SetupNewEvent}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/SetupIndoorEvent"
                  Component={SetupNewIndoorEvent}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/racesschedule"
                  Component={RacesSchedule}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/sitecontent"
                  Component={AnnouncementsBox}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/ISROS"
                  Component={ISROSDashboard}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/Substitutions"
                  Component={Substitutions}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/CoxWeight"
                  Component={CoxWeight}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/IndoorRaceResults"
                  Component={IndoorResults}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/PenaltyCards"
                  Component={PenaltyCardsPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/IndoorRecords"
                  Component={IndoorRecordsPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/disqualifications"
                  Component={DisqualificationsPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/Leaderboard"
                  Component={LeaderboardPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/commentary"
                  Component={CommentryPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/IDCard"
                  Component={CreateIDCardTemplate}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/ControlPanel"
                  Component={ControlPanelPage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/RAC2EDITOR"
                  Component={RaceDefinitionForm}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/Raceresultseditor"
                  Component={RaceDetails}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/oldcollections"
                  Component={OldCollections}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/backupandrestore"
                  Component={BackupAndRestorePage}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/downloadracefile"
                  Component={DownloadRaceFile}
                ></ProtectedRoute>
                <ProtectedRoute
                  exact
                  path="/IDCardManagement"
                  Component={IDCardManagementPage}
                ></ProtectedRoute>
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: "center", fontWeight: "bold" }}>
            Karachi Boat Club © 2023 | Website created by Muhammad Umar Mahar
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
};

export default MainLayout;

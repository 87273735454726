import React from "react";
import { Button, Result, Typography } from "antd";
import styles from "./isros.module.css";

import { Link } from "react-router-dom";

import OutdoorIcon from "../../Static/outdoorrowing.png";
import IndoorIcon from "../../Static/indoorrowing.png";
import CalendarIcon from "../../Static/calendar.png";
import subicon from "../../Static/substitute.png";
import weighticon from "../../Static/weightmachine.png";
import penaltyicon from "../../Static/penaltyIcon.png";
import dqicon from "../../Static/dqIcon.png";
import podiumicon from "../../Static/podiumIcon.png";
import recordsicon from "../../Static/recordsIcon.png";
import micstandicon from "../../Static/mic-stand.png";

const ISROSDashboard = () => {
  return (
    <>
      <h1>ISROS Dashboard</h1>
      <div className={styles.ButtonsFlexContainer}>
        <Link to="/Setupoutdoorevent" className={styles.BigButton}>
          <img src={OutdoorIcon} />
          <div>Setup OutDoor event</div>
        </Link>
        <Link to="/Setupindoorevent" className={styles.BigButton}>
          <img src={IndoorIcon} />
          <div>Setup Indoor event</div>
        </Link>
        <Link to="/racesschedule" className={styles.BigButton}>
          <img src={CalendarIcon} />
          <div>Schedule</div>
        </Link>
        <Link to="/indoorraceresults" className={styles.BigButton}>
          <img src={podiumicon} />
          <div>Indoor Race Results</div>
        </Link>
        <Link to="/substitutions" className={styles.BigButton}>
          <img src={subicon} />
          <div>Substitutions</div>
        </Link>
        <Link to="/coxweight" className={styles.BigButton}>
          <img src={weighticon} />
          <div>Cox Weight</div>
        </Link>
        <Link to="/PenaltyCards" className={styles.BigButton}>
          <img src={penaltyicon} />
          <div>Penalty Cards</div>
        </Link>
        <Link to="/disqualifications" className={styles.BigButton}>
          <img src={dqicon} />
          <div>Disqualifications</div>
        </Link>
        <Link to="/indoorrecords" className={styles.BigButton}>
          <img src={recordsicon} />
          <div>Indoor Records</div>
        </Link>
        <Link to="/commentary" className={styles.BigButton}>
          <img src={micstandicon} />
          <div>Commentary</div>
        </Link>
      </div>
    </>
  );
};

export default ISROSDashboard;

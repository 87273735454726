import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router";
import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Space,
  DatePicker,
  List,
  Popconfirm,
  Typography,
  Table,
  Tooltip,
  Tag,
  Select,
  Descriptions,
  notification,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ChromePicker } from "react-color";
import {
  ExclamationCircleOutlined,
  DeleteFilled,
  StopOutlined,
} from "@ant-design/icons";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  UpdateCMSDoc,
  GetCMSDoc,
  GetRestorePoints,
  CreateRestorePoint,
  RestoreCollection,
  RestoreAllCollections,
  DownloadAllBackups,
  DownloadCollectionBackup,
  GetRowers,
  GetSchools,
  UpdateRowerRFID,
} from "../../APIManager";

import moment from "moment";
import { setArgs } from "@craco/craco/lib/args";

const { confirm } = Modal;
const { TextArea } = Input;
const { Text } = Typography;
const { Option } = Select;
const IDCardManagementPage = () => {
  const [isLoading, SetIsLoading] = useState(true);

  const [rowers, SetRowers] = useState([]);
  const [schools, SetSchools] = useState([]);

  const [assignModalOpen, SetAssignModalOpen] = useState(false);

  useEffect(() => {
    FetchData();
  }, []);

  function FetchData() {
    SetIsLoading(true); // Start the loading state
    Promise.all([GetRowers(), GetSchools()])
      .then(([rowersRes, schoolsRes]) => {
        const schools = schoolsRes.data;

        // Map each rower and replace the Team field with the school object
        const updatedRowers = rowersRes.data.map((rower) => {
          const school = schools.find((school) => school._id == rower.Team._id);
          return { ...rower, Team: school }; // Replace the Team field
        });

        SetRowers(updatedRowers);
        SetSchools(schools);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        message.error("Failed to fetch data. Please try again later.");
      })
      .finally(() => {
        SetIsLoading(false); // End the loading state after all requests are done
      });
  }

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <div>
      <AssignIDCard
        isOpen={assignModalOpen}
        onClose={() => {
          SetAssignModalOpen(false);
        }}
        onSubmit={() => {
          SetAssignModalOpen(false);
          FetchData();
        }}
        rowers={rowers}
        schools={schools}
      />
      <Divider>
        <strong>ID Card Management</strong>
      </Divider>
      <Button onClick={() => [SetAssignModalOpen(true)]}>Assign RFID</Button>
    </div>
  );
};

const AssignIDCard = ({ isOpen, onClose, onSubmit, rowers, schools }) => {
  const [selectedRower, SetSelectedRower] = useState(null);

  const [rfid, SetRFID] = useState("");

  var rowerDetails = {};

  function ChangeSelectedRower(e) {
    SetSelectedRower(e);
    rowerDetails = { ...rowers.find((r) => r._id == e) };
    console.log(rowerDetails);
    SetRFID(rowerDetails.RFID);
  }

  if (selectedRower) {
    rowerDetails = { ...rowers.find((r) => r._id == selectedRower) };
  }

  console.log(rowerDetails);

  function OnSubmitPressed() {
    const duplicateID = rowers.find(
      (r) => r.RFID == rfid && r._id != selectedRower
    );

    if (duplicateID) {
      notification.error({
        message: "RFID already in use",
        description: `This rfid has already been assigned to ${duplicateID.Name}`,
      });

      return;
    }

    UpdateRowerRFID({ rowerID: selectedRower, RFID: rfid }).then((res) => {
      onSubmit();
    });
  }

  return (
    <>
      <Modal
        title={`Assign ID Card`}
        forceRender
        visible={isOpen}
        onOk={OnSubmitPressed}
        onCancel={onClose}
        okText="Save"
        cancelText="Cancel"
        width="600px"
        /*  okButtonProps={{
          disabled: !submitValid,
          onClick: () => {
            OnSubmit({
              times: formValues,
              schools: [...entries.map((e) => e.School.username)],
              eventname: formValues.EventName,
            });
          },
        }} */
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <div>
          <Select
            showSearch
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => {
              ChangeSelectedRower(e);
              console.log(e);
            }}
          >
            {rowers.map(function (r, i) {
              return (
                <Option value={r._id}>
                  {`${r.Name} (${r.Team.schoolinitials})`}
                </Option>
              );
            })}
          </Select>
          <br />
          <br />
          {selectedRower && (
            <div>
              <Descriptions layout="horizontal" bordered size="small">
                <Descriptions.Item label="Name" span={4}>
                  {rowerDetails.Name}
                </Descriptions.Item>
                <Descriptions.Item label="School" span={4}>
                  {rowerDetails.Team.schoolname}
                </Descriptions.Item>
                <Descriptions.Item label="RFID" span={4}>
                  <Input
                    value={rfid}
                    onChange={(e) => {
                      SetRFID(e.target.value);
                    }}
                  />
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default IDCardManagementPage;

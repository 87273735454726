import React, { useState, useContext, useEffect } from "react";
import { Prompt } from "react-router";
import styles from "./SchoolInfoPage.module.css";
import { AddNewSchool, GetSchoolNames } from "../../APIManager";
import { UserContext } from "../../UserContext";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  message,
  Space,
  Select,
} from "antd";
import ImgCrop from "antd-img-crop";
import { ChromePicker } from "react-color";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;
const { TextArea } = Input;
const AddSchoolInfoPage = () => {
  const [showErrorMessage, SetShowErrorMessage] = useState(false);
  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);
  const [form] = Form.useForm();
  const contactTypes = ["Primary", "Emergency", "None"];
  const requiredFields = [
    "SchoolName",
    "SchoolInitials",
    "Password",
    "SchoolType",
    "SchoolColor1",
  ];

  const [schoolNames, SetSchoolNames] = useState([]);

  useEffect(() => {
    GetSchoolNames().then((res) => {
      var schoolnames = [];
      res.data.forEach((s) => {
        schoolnames.push(s.username.toUpperCase());
      });
      SetSchoolNames(schoolnames);
      console.log(schoolnames);
    });
  }, []);

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to add a new school",
      okText: "Save",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        SetWaitingOnResponse(true);
        AddNewSchool(form.getFieldsValue())
          .then((res) => {
            SetWaitingOnResponse(false);
            window.location.reload();
          })
          .catch(() => {
            SetShowErrorMessage(true);
            message.warning(
              "A Server/Network Error Occured, please check your connection"
            );
            SetWaitingOnResponse(false);
          });
      },
      onCancel() {},
    });
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const ColorBar = ({ color }) => {
    return (
      <span
        style={{
          height: "20px",
          width: "5em",
          backgroundColor: color,
          borderWidth: "2px",
          borderStyle: "solid",
        }}
        hidden={!color}
      >
        {color}
      </span>
    );
  };
  return (
    <div className={styles.MainDiv}>
      {/*       <Prompt
        message={(location, action) => {
          if (action === "POP") {
            // console.log("Backing up...");
          }

          return location.pathname.startsWith("/app")
            ? true
            : `Are you sure you want to go to ${location.pathname}?`;
        }}
      /> */}
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={"vertical"}
        size="large"
        onChange={() => {
          // SetMessage(form.getFieldsValue());
          //console.log(form.getFieldsValue());
          //console.log(form.getFieldsError())
          // console.log(form.isFieldsTouched(["SchoolColor2"]));
        }}
      >
        <Form.Item
          label="School Name"
          name="SchoolName"
          rules={[
            {
              required: true,
              message: "Please input a School Name",
            },
            {
              message: "This name already exists",
              validator: (_, value) => {
                if (!schoolNames.includes(value.toUpperCase())) {
                  return Promise.resolve();
                } else {
                  return Promise.reject("This name already exists");
                }
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="School Initials"
          name="SchoolInitials"
          rules={[
            {
              required: true,
              message: "Please input School Initials",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Space>
          {" "}
          <Form.Item label="" name="University" valuePropName="checked">
            <Checkbox>University Status</Checkbox>
          </Form.Item>
          <Form.Item label="" name="International" valuePropName="checked">
            <Checkbox>International Status</Checkbox>
          </Form.Item>
        </Space>

        <Form.Item
          label="School Type"
          name="SchoolType"
          rules={[
            {
              required: true,
              message: "Please select the type of school",
            },
          ]}
          autoComplete="off"
        >
          <Select
            options={[
              { value: "School", label: "School" },
              { value: "College", label: "College" },
              { value: "University", label: "University" },
            ]}
            autoComplete="off"
          />
        </Form.Item>

        <Form.Item
          label="Password"
          name="Password"
          rules={[
            {
              required: true,
              message: "Please input a password",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Button
          onClick={() => {
            var password = String(
              Math.floor(100000000 + Math.random() * 900000000)
            );
            form.setFieldsValue({ Password: password });
          }}
        >
          Generate Random Password
        </Button>
        <Form.Item label="School Colors" style={{ marginBottom: 0 }}>
          <Form.Item
            label="School Color 1"
            name="SchoolColor1"
            defaultValue="#4r4r4r"
            rules={[{ required: true }]}
            style={{ display: "inline-block" }}
          >
            <ColorPicker showIgnore={false} />
          </Form.Item>
          <Form.Item
            label="School Color 2"
            name="SchoolColor2"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              margin: "0 8px",
            }}
          >
            <ColorPicker showIgnore={true} />
          </Form.Item>
          <Form.Item
            label="School Color 3"
            name="SchoolColor3"
            rules={[{ required: false }]}
            style={{
              display: "inline-block",
              margin: "0 8px",
            }}
          >
            <ColorPicker showIgnore={true} />
          </Form.Item>
        </Form.Item>
        <Divider />
        <Form.Item shouldUpdate={true}>
          {() => {
            // console.log(form.isFieldsTouched(["Declration"]))
            return (
              <Button
                size={"large"}
                block
                type="primary"
                htmlType="submit"
                disabled={
                  !requiredFieldsTouched(form, requiredFields) ||
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                Add new school
              </Button>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddSchoolInfoPage;

function requiredFieldsTouched(form, requiredFields) {
  var returnValue = true;
  requiredFields.forEach((element) => {
    if (!form.isFieldsTouched([element])) {
      // console.log("returned false" + element);
      returnValue = false;
    }
  });
  return returnValue;
}

const ColorPicker = ({ value, onChange, showIgnore }) => {
  if (value === undefined) {
    value = { color: "#000000", ignore: showIgnore };
  }
  return (
    <div>
      <ChromePicker
        color={value.color}
        onChange={(color) => {
          if (value.ignore) {
            //   return; This can be unccomented to prevent changing of color while ignore is true
          }
          onChange({ color: color.hex, ignore: value.ignore });
        }}
      />
      <div style={{ backgroundColor: value.color, height: "30px" }}></div>
      {showIgnore ? (
        <Checkbox
          checked={value.ignore}
          onChange={(e) =>
            onChange({ color: value.color, ignore: e.target.checked })
          }
        >
          Ignore Color
        </Checkbox>
      ) : (
        <></>
      )}
    </div>
  );
};

const FileUpload = ({ value, onChange, onUpload }) => {
  return (
    <Upload
      maxCount={1}
      accept=".png,.jpg,.jpeg,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      onRemove={() => {
        onChange("");
      }}
      onChange={() => {}}
      beforeUpload={(file) => {
        let reader = new FileReader();
        reader.addEventListener("load", function (e) {
          let text = e.target.result;
          onChange(text);
        });
        reader.readAsDataURL(file);

        // Prevent upload
        return false;
      }}
    >
      <Button>Select File</Button>
    </Upload>
  );
};

const LogoFileUpload = ({ value, onChange, onUpload }) => {
  return (
    <ImgCrop rotate aspect={1 / 1}>
      <Upload
        maxCount={1}
        accept=".png,.jpg,.jpeg"
        listType="picture-card"
        onRemove={() => {
          onChange("");
        }}
        onChange={() => {}}
        beforeUpload={(file) => {
          let reader = new FileReader();
          reader.addEventListener("load", function (e) {
            let text = e.target.result;
            onChange(text);
          });
          reader.readAsDataURL(file);
          //Dont Prevent upload to avoid thumbnail bug hence returning true
          return true;
        }}
      >
        <Button>Select File</Button>
      </Upload>
    </ImgCrop>
  );
};

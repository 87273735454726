import { React, useContext } from "react";
import { Table, Button, Modal, Checkbox, Input, Select } from "antd";
import { UserContext } from "../../UserContext";
import { GetSchoolInfo, EditSchool } from "../../APIManager";

import ReadOnlyUploadedFile from "../Shared Components/ReadOnlyUploadedFile";
import { EditOutlined } from "@ant-design/icons";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { ChromePicker } from "react-color";

export default function ViewSchoolInformation({
  schoolid,
  modalVisible,
  closeModal,
}) {
  const history = useHistory();
  const [school, SetSchool] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [editedFields, setEditedFields] = useState({});

  const [editColourModalOpen, SetEditColourModalOpen] = useState(false);
  const [selectedColourIndex, SetSelectedColourIndex] = useState(null);
  const [newColor, SetNewColor] = useState(null);
  const [newIgnoreColor, SetNewIgnoreColor] = useState(false);

  useEffect(() => {
    if (schoolid) {
      FetchData();
    }
  }, [schoolid]);

  function FetchData() {
    GetSchoolInfo(schoolid).then((res) => {
      SetSchool(res.data);
    });
  }

  if (!schoolid || !school.username) {
    return null;
  }

  const dataSource = GetDataSource(school);
  const columns = [
    {
      dataIndex: "Key",
      key: "Key",
    },
    {
      dataIndex: "Value",
      key: "Value",
      render: (text, record) => {
        if (
          editMode &&
          (record.Key === "School Name" ||
            record.Key === "School Initials" ||
            record.Key === "School Type")
        ) {
          if (record.Key === "School Type") {
            return (
              <Select
                value={editedFields[record.Key] ?? record.Value}
                onChange={(value) =>
                  setEditedFields({
                    ...editedFields,
                    [record.Key]: value,
                  })
                }
                options={[
                  { value: "School", label: "School" },
                  { value: "College", label: "College" },
                  { value: "University", label: "University" },
                ]}
                autoComplete="off"
                style={{ width: "100%" }}
              />
            );
          } else {
            return (
              <Input
                value={editedFields[record.Key] ?? record.Value}
                onChange={(e) =>
                  setEditedFields({
                    ...editedFields,
                    [record.Key]: e.target.value,
                  })
                }
              />
            );
          }
        }

        if (record?.Key.includes("Rowing Color")) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ backgroundColor: record.Value, flexGrow: 1 }}>
                {record.Value}
              </div>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => {
                  const index = record.Key.slice(-1) - 1;
                  const c = school.rowingcolors[index];
                  SetSelectedColourIndex(index);
                  SetNewColor(c);
                  SetNewIgnoreColor(c == null ? true : false);
                  SetEditColourModalOpen(true);
                }}
              />
            </div>
          );
        } else {
          return record.Value;
        }
      },
    },
  ];

  const handleSave = () => {
    const updates = {};
    if (editedFields["School Name"]) {
      updates.schoolname = editedFields["School Name"];
    }
    if (editedFields["School Initials"]) {
      updates.schoolinitials = editedFields["School Initials"];
    }
    if (editedFields["School Type"]) {
      updates.schooltype = editedFields["School Type"];
    }

    EditSchool({
      schoolID: school._id,
      updates,
    }).then(() => {
      FetchData();
      setEditMode(false);
      setEditedFields({});
    });
  };

  return (
    <Modal
      title="School Details"
      forceRender
      visible={modalVisible}
      onOk={() => {}}
      onCancel={closeModal}
      okText="Delete"
      cancelText="cancel"
      width="800px"
      okButtonProps={{
        hidden: true,
      }}
      cancelButtonProps={{ hidden: true }}
      maskClosable={false}
    >
      <Modal
        title={`Update Colour ${selectedColourIndex + 1}`}
        visible={editColourModalOpen}
        onCancel={() => {
          SetEditColourModalOpen(false);
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              SetEditColourModalOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              const newRowingColours = school.rowingcolors;
              newRowingColours[selectedColourIndex] = newIgnoreColor
                ? null
                : newColor;

              EditSchool({
                schoolID: school._id,
                updates: { rowingcolors: newRowingColours },
              }).then(() => {
                SetEditColourModalOpen(false);
                FetchData();
              });
            }}
          >
            Save
          </Button>,
        ]}
      >
        <div style={{ marginTop: "20px" }}>
          <ColorPicker
            value={newColor}
            onChange={(color) => {
              SetNewColor(color);
            }}
            isIgnore={newIgnoreColor}
            onIngoreChange={() => {
              SetNewIgnoreColor(!newIgnoreColor);
              if (newIgnoreColor) {
                SetNewColor("#ffffff");
              }
            }}
          />
        </div>
      </Modal>

      <div>
        <div>
          <h2>School Info</h2>
          <Button
            type="primary"
            style={{ marginTop: "20px" }}
            onClick={() => {
              if (editMode) {
                handleSave();
              } else {
                setEditMode(true);
              }
            }}
          >
            {editMode ? "Save" : "Edit"}
          </Button>
          <Table dataSource={dataSource} columns={columns} pagination={false} />
          <br />
          <h2>School File Uploads</h2>
          <ul>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="School Declaration"
                FileURL={school.schooldeclaration}
              />
            </li>
            <li>
              <ReadOnlyUploadedFile
                FileLabel="School Logo"
                FileURL={school.schoollogo}
              />
            </li>
          </ul>
        </div>
        <br />
      </div>
    </Modal>
  );

  function GetDataSource(school) {
    var mappings = {
      "School Name": school.schoolname,
      "User Name": school.username,
      Password: school.recovery,
      "School Initials": school.schoolinitials,
      "School Type": school.schooltype,
      University: school.university ? "Yes" : "No",
      International: school.international ? "Yes" : "No",
      "School Address": school.address,
      "School Email Address": school.emailaddress,
      Website: school.website,
      "AGM Voter": school.agmvotename,
      "Principal Name": school.principal?.Name,
      "Principal Title": school.principal?.Title,
      "Principal Email": school.principal?.Email,
      "Principal Phone Number": school.principal?.PhoneNumber,
      "Rowing Coordinator Name": school.rowingcoordinator?.Name,
      "Rowing Coordinator Title": school.rowingcoordinator?.Title,
      "Rowing Coordinator Email": school.rowingcoordinator?.Email,
      "Rowing Coordinator Phone Number": school.rowingcoordinator?.PhoneNumber,
      "Coach Name": school.coach?.Name,
      "Coach Email": school.coach?.Email,
      "Coach Phone Number": school.coach?.PhoneNumber,
      "Rowing Color 1": school.rowingcolors[0],
      "Rowing Color 2": school.rowingcolors[1],
      "Rowing Color 3": school.rowingcolors[2],
      "Best Cox Nomination": school.bestcoxnomination,
      Justification: school.bestcoxnominationjustification,
    };

    var keys = Object.keys(mappings);
    var dataSource = [];
    keys.forEach((key) => {
      dataSource.push({
        Key: key,
        Value: mappings[key],
      });
    });
    return dataSource;
  }
}

const ColorPicker = ({ value, onChange, isIgnore, onIngoreChange }) => {
  if (isIgnore) {
    value = "#000000";
  }
  console.log(value);

  return (
    <div>
      <ChromePicker
        color={value}
        onChange={(color) => {
          if (isIgnore) {
            //onChange(null);
          } else {
            onChange(color.hex);
          }
        }}
      />
      <div style={{ backgroundColor: value, height: "30px" }}></div>

      <Checkbox checked={isIgnore} onChange={onIngoreChange}>
        Ignore Color
      </Checkbox>
    </div>
  );
};

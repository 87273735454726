import React, { useState, useContext, useEffect } from "react";
import { useLocation, useHistory, bro } from "react-router-dom";
import { Layout, Menu, Modal } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  HomeOutlined,
  ExclamationCircleOutlined,
  UsergroupAddOutlined,
  BankOutlined,
  CheckCircleOutlined,
  FilePdfOutlined,
  DollarCircleOutlined,
  FlagOutlined,
  PushpinOutlined,
  SettingOutlined,
  ControlOutlined,
  FolderOpenOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import styles from "./MainLayout.module.css";
import { ConfigContext } from "../../ConfigContext";

const { Sider } = Layout;
const { confirm } = Modal;
const SideBar = () => {
  const location = useLocation();
  const history = useHistory();
  const selectedKey = GetSelectedKey();
  let [config, SetConfig] = useContext(ConfigContext);

  var backListener;
  const [locationKeys, setLocationKeys] = useState([]);
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);
  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
      className={styles.SideBar}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]}
        className={styles.SideBarFixed}
      >
        <Menu.ItemGroup title="ISR 2024 Registrations"></Menu.ItemGroup>
        <Menu.Item
          key="1"
          icon={<HomeOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/");
          }}
        >
          Home
        </Menu.Item>
        <Menu.SubMenu
          key="RegistrationsMenu"
          icon={<BankOutlined />}
          title="Registrations"
        >
          <Menu.Item
            key="AddNewSchool"
            icon={<UsergroupAddOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/addnewschool");
            }}
          >
            Add School
          </Menu.Item>
          <Menu.Item
            key="Schools"
            icon={<BankOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/schools");
            }}
          >
            Schools
          </Menu.Item>
          <Menu.Item
            key="Status"
            icon={<CheckCircleOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/status");
            }}
          >
            Status
          </Menu.Item>
          <Menu.Item
            key="Payments"
            icon={<DollarCircleOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/Payments");
            }}
          >
            Payments
          </Menu.Item>
          <Menu.Item
            key="Flags"
            icon={<FlagOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/Flags");
            }}
          >
            Flags
          </Menu.Item>
        </Menu.SubMenu>
        <Menu.Item
          key="Reports"
          icon={<FilePdfOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/reports");
          }}
        >
          Reports
        </Menu.Item>
        <Menu.SubMenu
          key="SiteAdminMenu"
          icon={<SettingOutlined />}
          title="Site Admin"
        >
          <Menu.Item
            key="SiteContent"
            icon={<PushpinOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/SiteContent");
            }}
          >
            Site Content
          </Menu.Item>
          <Menu.Item
            key="ControlPanel"
            icon={<ControlOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/controlpanel");
            }}
          >
            Control Panel
          </Menu.Item>
          <Menu.Item
            key="Old Collections"
            icon={<FolderOpenOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/oldcollections");
            }}
          >
            Old Collections
          </Menu.Item>
          <Menu.Item
            key="Backup and Restore"
            icon={<FieldTimeOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/backupandrestore");
            }}
          >
            Backups
          </Menu.Item>
          <Menu.Item
            key="UserManagement"
            icon={<UserOutlined />}
            onClick={() => {
              OnMenuButtonClicked("/UserManagement");
            }}
          >
            Users
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item
          key="ISROS"
          icon={<PushpinOutlined />}
          onClick={() => {
            OnMenuButtonClicked("/ISROS");
          }}
        >
          ISROS
        </Menu.Item>
      </Menu>
    </Sider>
  );
  function GetSelectedKey() {
    var path = location.pathname;
    switch (path) {
      case "/":
        return "1";
      case "/schools":
        return "Schools";
      case "/addnewschool":
        return "AddNewSchool";
      case "/status":
        return "Status";
      case "/reports":
        return "Reports";
      case "/Payments":
        return "Payments";
      case "/Flags":
        return "Flags";
      case "/Announcements":
        return "Announcements";
      case "/UserManagement":
        return "UserManagement";
      case "/ISROS":
        return "ISROS";
      case "/SiteContent":
        return "SiteContent";
      case "/controlpanel":
        return "ControlPanel";
      case "/oldcollections":
        return "Old Collections";
      case "/backupandrestore":
        return "Backup and Restore";
      default:
        break;
    }
  }

  function OnMenuButtonClicked(path) {
    if (path === history.location) {
      return;
    }
    if (config.showUnsavedProgress) {
      confirm({
        title: "Confirm",
        icon: <ExclamationCircleOutlined />,
        content: "You may have unsaved progress. Are you sure you want to quit",
        okText: "Yes",
        okType: "primary",
        cancelText: "cancel",
        onOk() {
          history.push(path);
        },
        onCancel() {},
      });
    } else {
      history.push(path);
    }
  }
};

export default SideBar;

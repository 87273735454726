import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { GetRaces, GetSchools, GetRowers } from "../../APIManager";

import { Divider, Descriptions, Collapse, Button } from "antd";

import { GetIndoorRaceSplitDetails } from "../../utility";

export default function DownloadRaceFile() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const raceName = searchParams.get("RaceName");
  const splitDetails = GetIndoorRaceSplitDetails(raceName);

  const [schools, SetSchools] = useState([]);
  const [rowers, SetRowers] = useState([]);
  const [races, SetRaces] = useState([]);

  const [isLoading, SetIsLoading] = useState(true);

  const selectedRace = races.filter((r) => r.RaceName == raceName)[0];

  console.log(selectedRace);

  const name_long = raceName;
  const name_short = "TODO";
  const duration_type = "meters";
  const duration = splitDetails.totalDistance;
  const race_type = splitDetails.type;
  const split_type = "even";
  const split_value = splitDetails.splitDistance;
  const race_id = selectedRace?._id;
  const sound_horn_at_splits = 1;
  const classes = [{ name: simplifyRaceName(raceName) }];

  const boats = GetBoats();

  function GetBoats() {
    if (isLoading) {
      return [];
    }

    const boats = [];

    selectedRace.Participants.forEach((p, index) => {
      const intials = schools.filter(
        (s) => String(s._id) == String(p.school)
      )[0].schoolinitials;

      boats.push({
        class_name: classes[0].name,
        lane_number: index + 1,
        name: intials,
        participants: [],
      });
    });

    return boats;
  }

  console.log(boats);

  const handleDownload = () => {
    // Define the JavaScript object
    const data = {
      name_long,
      name_short,
      duration_type,
      duration,
      race_type,
      split_type,
      split_value,
      race_id,
      classes,
      boats,
    };

    // Convert the object to a JSON string
    const jsonString = JSON.stringify(data, null, 2);

    // Create a Blob from the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a link element
    const link = document.createElement("a");

    // Set the link's href to a URL representing the Blob
    link.href = URL.createObjectURL(blob);

    // Set the download attribute with the desired file name
    link.download = `${selectedRace.RaceName}.rac2`;

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the URL object
    URL.revokeObjectURL(link.href);
  };

  useEffect(() => {
    FetchDetails();
  }, []);

  async function FetchDetails() {
    try {
      // Wait for all API calls to complete
      const [schoolsRes, /*  rowersRes, */ racesRes] = await Promise.all([
        GetSchools(),
        //GetRowers(),
        GetRaces(),
      ]);

      // Update state after fetching all data
      SetSchools(schoolsRes.data);
      //SetRowers(rowersRes.data);
      SetRaces(racesRes.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // Set loading to false once everything is done
      SetIsLoading(false);
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Button type="primary" onClick={handleDownload}>
        Download
      </Button>
      <br />
      <br />

      <Descriptions layout="horizontal" bordered size="small">
        <Descriptions.Item label="Name Long" span={4}>
          {raceName}
        </Descriptions.Item>
        <Descriptions.Item label="Name Short" span={4}>
          {name_short}
        </Descriptions.Item>
        <Descriptions.Item label="Duration Type" span={4}>
          {duration_type}
        </Descriptions.Item>
        <Descriptions.Item label="Duration" span={4}>
          {duration}
        </Descriptions.Item>
        <Descriptions.Item label="Race Type" span={4}>
          {race_type}
        </Descriptions.Item>
        <Descriptions.Item label="Split Type" span={4}>
          {split_type}
        </Descriptions.Item>
        <Descriptions.Item label="Split Value" span={4}>
          {split_value}
        </Descriptions.Item>
        <Descriptions.Item label="Race ID" span={4}>
          {race_id}
        </Descriptions.Item>
        <Descriptions.Item label="Class Name" span={4}>
          {classes[0].name}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

const simplifyRaceName = (raceName) =>
  raceName.replace(/\s+x\s+/g, "x").replace(/\s+Race\s+[A-Z]$/, "");

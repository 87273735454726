import React from "react";

import { Checkbox } from "antd";

export default function PermissionsComponent({ value, onChange }) {
  return (
    <Checkbox.Group
      onChange={(e) => onChange(e)}
      value={value}
      options={[
        "All",
        "Add Schools",
        "Schools",
        "Schools Form Status",
        "School Reports",
        "School Payments",
        "School Flags",
        "Announcements",
        "User Management",
        "ISROS Dashboard",
        "Setup Outdoor Event",
        "Setup Indoor Event",
        "Substitutions",
        "Full Schedule Access",
        "Readonly Schedule Access",
        "Lock Pontoon Sheet",
        "Indoor Race Results",
        "Cox Weights",
        "Disqualifications",
        "Penalty Cards",
        "Indoor Records",
        "Commentary",
        "Site Content",
        "Control Panel",
        "Old Collections",
        "Cox Weight",



      ]}
    />
  );
}

import React from "react";

import {
  Modal,
  Input,
  Button,
  Alert,
  Collapse,
  Tooltip,
  Descriptions,
  Divider,
} from "antd";

import { useState, useEffect } from "react";
import styles from "./RacesSchedule.module.css";
import { FormatTime } from "./RacesSchedule";

import {
  GetSchools,
  GetEntriesForEvent,
  DownloadRAC2File,
} from "../../APIManager";
import { useViewport } from "react-flow-renderer";

import RaceProgressionDiagramModal from "./RaceProgressionDiagramModal";
import PontoonSheetModal from "./PontoonSheetModal";
import NewPontoonSheetModal from "./PontoonSheetNewModal";

import { GetRaceClass } from "../../utility";

const { Panel } = Collapse;

export default function RaceDetailsModal({
  modalVisible,
  onCloseModal,
  race,
  allEventEntries,
  day,
  schools,
  RefreshRace,
}) {
  const [entries, SetEntries] = useState();

  const [progressionModalOpen, SetProgressionModalOpen] = useState(false);
  const [pontoonsheetModal, SetPontoonsheetModalOpen] = useState(false);

  useEffect(() => {}, []);

  useEffect(() => {
    if (race?.EventName) {
      GetEntriesForEvent(race?.EventName).then((res) => {
        SetEntries(res.data);
      });
    }
  }, [modalVisible, race]);

  function GetSchoolInfo(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  if (
    !race ||
    !allEventEntries?.length > 0 ||
    !entries?.length > 0 ||
    !schools?.length > 0
  ) {
    return <></>;
  }

  var awaitingResults = false;
  race.Participants.forEach((p, index) => {
    if (p.isPlaceHolder) {
      awaitingResults = true;
    }
  });

  return (
    <div>
      {!awaitingResults && (
        <NewPontoonSheetModal
          modalVisible={pontoonsheetModal}
          onCloseModal={() => {
            SetPontoonsheetModalOpen(false);
            RefreshRace();
          }}
          race={race}
          schools={schools}
          entries={entries}
          day={day}
          key={`${pontoonsheetModal} pontoonsheetmodal`}
        />
      )}
      {GetRaceClass(race.EventName) == "Water Race" && (
        <RaceProgressionDiagramModal
          modalVisible={progressionModalOpen}
          onCloseModal={() => {
            SetProgressionModalOpen(false);
          }}
          eventName={race.EventName}
          schools={schools}
        />
      )}

      <Modal
        title={race.RaceName}
        forceRender
        visible={modalVisible}
        onCancel={onCloseModal}
        okText="Change"
        cancelText="Close"
        width="700px"
        okButtonProps={{
          hidden: true,
        }}
        maskClosable
      >
        {race.Warnings.length > 0 && (
          <ScheduleWarningsAlert warnings={race.Warnings} />
        )}
        <Divider>
          <strong>Race Details</strong>
        </Divider>
        <Descriptions layout="horizontal" bordered size="small">
          <Descriptions.Item label="Event Name" span={4}>
            {race.EventName}
          </Descriptions.Item>
          <Descriptions.Item label="Race" span={4}>
            {race.RaceType}
          </Descriptions.Item>
          <Descriptions.Item label="Day" span={4}>
            {day}
          </Descriptions.Item>
          <Descriptions.Item label="Race Number" span={4}>
            {race.RaceNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Time Slot" span={4}>
            {FormatTime(race.TimeStart)} ~ {FormatTime(race.TimeEnd)}
          </Descriptions.Item>
          <Descriptions.Item label="Race Status" span={4}>
            {race.RaceStatus}
          </Descriptions.Item>
        </Descriptions>
        {/*         <div className={styles.RaceDetailsContainer}>
          <div>
            <label>Event Name:</label> <span>{race.EventName}</span>
          </div>
          <div>
            <label>Race:</label> <span>{race.RaceType}</span>
          </div>
          <div>
            <label>Day:</label> <span>{day} </span>
          </div>
          <div>
            <label>Race Number:</label> <span>{race.RaceNumber}</span>
          </div>
          <div>
            <label>Time Start:</label>
            <span>{FormatTime(race.TimeStart)}</span>
          </div>
          <div>
            <label>Time End:</label> <span>{FormatTime(race.TimeEnd)}</span>
          </div>
          <div>
            <label>Race Status:</label> <span>{race.RaceStatus}</span>
          </div>
        </div> */}
        <Divider>
          <strong>Race Participants</strong>
        </Divider>
        <RaceParticipants
          participants={race.Participants}
          schools={schools}
          entries={entries}
          eventName={race.EventName}
        />
        <br />
        <Button
          onClick={() => {
            SetProgressionModalOpen(true);
          }}
          hidden={GetRaceClass(race.EventName) == "Indoor Race"}
          style={{ marginBottom: "1em" }}
          block
        >
          Show Progression
        </Button>
        <Tooltip
          title={
            awaitingResults ? "Still awaiting Results of previous race" : ""
          }
        >
          <Button
            onClick={() => {
              SetPontoonsheetModalOpen(true);
              RefreshRace();
            }}
            disabled={awaitingResults}
            type="primary"
            block
          >
            Pontoon Sheet
          </Button>
          <br />
          <br />
          <Button
            onClick={() => {
              DownloadRAC2File(race.RaceName);
            }}
            disabled={awaitingResults}
            type="primary"
            block
            hidden={GetRaceClass(race.EventName) != "Indoor Race"}
          >
            Download .rac2
          </Button>
        </Tooltip>

        {race.RaceResults.length > 0 && (
          <>
            <Divider>
              <strong>Race Results</strong>
            </Divider>
            <Descriptions layout="horizontal" bordered size="small">
              {race.RaceResults.map((id, index) => {
                return (
                  <>
                    <Descriptions.Item
                      label={<strong>{ordinal_suffix_of(index + 1)}</strong>}
                      span={4}
                    >
                      <span>{GetSchoolInfo(id).schoolname}</span>
                    </Descriptions.Item>
                  </>
                );
              })}
            </Descriptions>
          </>
        )}
      </Modal>
    </div>
  );
}

const RaceParticipants = ({ participants, schools, entries, eventName }) => {
  function GetSchool(id) {
    return schools.filter((s) => s._id == id)[0];
  }

  return (
    <div className={styles.RaceDetailsParticipants}>
      <div className={styles.RaceDetailsContainer}>
        <Descriptions layout="horizontal" bordered size="small">
          {participants.map((p, index) => {
            return (
              <Descriptions.Item
                label={<strong>{`Lane\u00A0${index + 1}`}</strong>}
                span={2}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (p.isPlaceHolder) {
                      return;
                    }
                    var entry = entries.filter(
                      (e) =>
                        e.RaceName == eventName &&
                        e.Team == GetSchool(p.school)._id
                    )[0];
                    Modal.info({
                      title: `Rowers for ${GetSchool(p.school).schoolname}`,
                      content: (
                        <div className={styles.RowerNamesList}>
                          <p>
                            <strong>Rowers:</strong>
                          </p>
                          {entry.Rowers.map((r) => (
                            <p>{r.Name}</p>
                          ))}
                          <p>
                            <strong>Cox:</strong>
                          </p>
                          {entry.Coxers.map((r) => (
                            <p>{r.Name}</p>
                          ))}
                          <p>
                            <strong>Subs:</strong>
                          </p>
                          {entry.SubRowers.map((r) => (
                            <p>{r.Name}</p>
                          ))}
                          <p>
                            <strong>Sub Cox:</strong>
                          </p>
                          {entry.SubCoxers.map((r) => (
                            <p>{r.Name}</p>
                          ))}
                        </div>
                      ),
                      onOk() {},
                    });
                  }}
                >
                  <span
                    style={{
                      textDecoration: p.isDisqualified
                        ? "line-through"
                        : "none",
                    }}
                  >
                    {p.isPlaceHolder
                      ? `${p.school} (Awaiting Results)`
                      : GetSchool(p.school).schoolname}
                  </span>
                </div>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      </div>
    </div>
  );
};

const SchoolCollapseRow = ({}) => {};

const ScheduleWarningsAlert = ({ warnings }) => {
  return (
    <Alert
      message="Warning"
      //description={`You have not assigned ${no} students to any race However, They will stil be incuded in the total registration fees. It is best if you either assign them to a race or delete them from the Team Registration Page`}
      description={
        <div>
          {warnings.map((w) => (
            <p>{w}</p>
          ))}
        </div>
      }
      type="warning"
      showIcon
      className={styles.RaceDetailsAlert}
    />
  );
};

function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

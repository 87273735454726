import React from "react";

import { Modal, Input, Button, Select, Form } from "antd";

import { useState, useEffect } from "react";

import { GetRaceClass } from "../../utility";

export default function ChooseDayInsertModal({
  modalVisible,
  onCloseModal,
  RaceName,
  Days,
  OnSubmit,
}) {
  const [selectedDay, SetSelectedDay] = useState(false);

  const raceClass = GetRaceClass(RaceName);
  const allowedDays = Days.filter((d) => {
    if (raceClass == "Indoor Race") {
      return d.Class == "Indoor";
    }
    if (raceClass == "Water Race") {
      return d.Class == "Outdoor";
    }
  });

  console.log(Days);

  return (
    <div>
      <Modal
        title={`Add Race to schedule`}
        forceRender
        visible={modalVisible}
        onOk={() => {
          OnSubmit(selectedDay);
        }}
        onCancel={onCloseModal}
        okText="Change"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{
          disabled: !selectedDay,
        }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        Selected Race: <strong>{RaceName}</strong>
        <br />
        <div style={{ width: "100%" }}>
          {" "}
          <Select
            style={{ width: "100%" }}
            options={allowedDays.map((e) => {
              return { value: e.Day, label: e.Day };
            })}
            onChange={(e) => {
              SetSelectedDay(e);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}

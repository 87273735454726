import React, { useState, useContext, useEffect } from "react";
import { Modal, Form, Input, Button, Checkbox, Divider, message } from "antd";
import { ChromePicker } from "react-color";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { GetSchoolNames } from "../../APIManager";

const { confirm } = Modal;

const EditSchoolInfoPage = ({ school, isVisible, onClose }) => {
  const [form] = Form.useForm();
  const [schoolNames, setSchoolNames] = useState([]);

  useEffect(() => {
    if (school) {
      form.setFieldsValue({
        SchoolName: school.schoolname,
        SchoolInitials: school.schoolinitials,
        SchoolColor1: school.rowingcolors[0]
          ? school.rowingcolors[0]
          : "#000000",
        SchoolColor2: school.rowingcolors[1]
          ? school.rowingcolors[1]
          : "#000000",
        SchoolColor3: school.rowingcolors[2]
          ? school.rowingcolors[2]
          : "#000000",
      }); // Set the form values, defaulting to black if null
    }
    GetSchoolNames().then((res) => {
      const names = res.data.map((s) => s.username.toUpperCase());
      setSchoolNames(names);
    });
  }, [school]);

  const onFinish = (values) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to update this school information",
      okText: "Save",
      okType: "primary",
      cancelText: "Back",
      onOk() {},
      onCancel() {
        console.log("Update cancelled");
      },
    });
  };

  const ColorPicker = ({ value, onChange }) => {
    if (value === undefined) {
      value = { color: "#000000" };
    }
    return (
      <div>
        <ChromePicker
          color={value.color}
          onChange={(color) => onChange({ color: color.hex })}
        />
        <div style={{ backgroundColor: value.color, height: "30px" }}></div>
      </div>
    );
  };

  // Custom validation to ignore certain colors and check if any colors are null
  const validateColors = (_, values) => {
    const { SchoolColor1, SchoolColor2, SchoolColor3 } = values;

    if (
      SchoolColor1 === SchoolColor2 ||
      SchoolColor1 === SchoolColor3 ||
      SchoolColor2 === SchoolColor3
    ) {
      return Promise.reject(
        "Duplicate colors detected. Please use different colors."
      );
    }

    // Handle null colors by ignoring them in validation
    if ([SchoolColor1, SchoolColor2, SchoolColor3].includes("#000000")) {
      return Promise.reject("Please select valid distinct colors.");
    }

    return Promise.resolve();
  };

  return (
    <Modal
      title="Edit School Information"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        layout={"vertical"}
        size="large"
        onValuesChange={validateColors} // Validate color uniqueness on change
      >
        <Form.Item
          label="School Name"
          name="SchoolName"
          rules={[
            {
              required: true,
              message: "Please input a School Name",
            },
            {
              message: "This name already exists",
              validator: (_, value) =>
                !schoolNames.includes(value.toUpperCase())
                  ? Promise.resolve()
                  : Promise.reject("This name already exists"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="School Initials"
          name="SchoolInitials"
          rules={[
            {
              required: true,
              message: "Please input School Initials",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="School Colors" style={{ marginBottom: 0 }}>
          <Form.Item
            label="School Color 1"
            name="SchoolColor1"
            rules={[{ required: true }]}
            style={{ display: "inline-block" }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label="School Color 2"
            name="SchoolColor2"
            style={{ display: "inline-block", margin: "0 8px" }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label="School Color 3"
            name="SchoolColor3"
            style={{ display: "inline-block", margin: "0 8px" }}
          >
            <ColorPicker />
          </Form.Item>
        </Form.Item>

        <Divider />

        <Form.Item>
          <Button
            size={"large"}
            block
            type="primary"
            htmlType="submit"
            disabled={
              !form.isFieldTouched("SchoolName") ||
              !form.isFieldTouched("SchoolInitials") ||
              !form.isFieldTouched("SchoolColor1") ||
              form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            Save Changes
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSchoolInfoPage;

function requiredFieldsTouched(form, requiredFields) {
  return requiredFields.every((field) => form.isFieldTouched(field));
}

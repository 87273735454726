import React from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "./UserContext";
import { useContext } from "react";
import { getLoggedInUser } from "./APIManager";
import { isEqual } from "lodash";

import { useLocation } from "react-router-dom";

import NotAuthorizedPage from "./Components/MainLayout/NotAuthorizedPage";

export const ProtectedRoute = ({ Component: Component, ...rest }) => {
  const Location = useLocation();
  const [user, setUser] = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        ReCheckAuth();
        if (user.isloggedin) {
          if (IsAllowedModule()) {
            return <Component {...props} />;
          } else {
            return <NotAuthorizedPage />;
          }
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );

  function ReCheckAuth() {
    getLoggedInUser().then((res) => {
      if (!isEqual(user, res.data)) {
        setUser(res.data);
      }
    });
  }

  function IsAllowedModule() {
    if (user.allowedmodules?.includes("All")) {
      return true;
    }

    var currentPath = Location.pathname.toLocaleLowerCase();
    switch (currentPath) {
      case "/":
        return true;
      case "/schools":
        return HasPerm("Schools");
      case "/status":
        return HasPerm("Schools Form Status");
      case "/reports":
        return HasPerm("School Reports");
      case "/payments":
        return HasPerm("School Payments");
      case "/flags":
        return HasPerm("School Flags");
      case "/usermanagement":
        return HasPerm("User Management");
      case "/addnewschool":
        return HasPerm("Add Schools");
      case "/announcements":
        return HasPerm("Announcements");
      case "/isros":
        return HasPerm("ISROS Dashboard");
      case "/setupoutdoorevent":
        return HasPerm("Setup Outdoor Event");
      case "/setupindoorevent":
        return HasPerm("Setup Indoor Event");
      case "/substitutions":
        return HasPerm("Substitutions");
      case "/indoorraceresults":
        return HasPerm("Indoor Race Results");
      case "/coxweight":
        return HasPerm("Cox Weights");
      case "/penaltycards":
        return HasPerm("Penalty Cards");
      case "/indoorrecords":
        return HasPerm("Indoor Records");
      case "/disqualifications":
        return HasPerm("Disqualifications");
      case "/commentary":
        return HasPerm("Commentary");
      case "/sitecontent":
        return HasPerm("Site Content");
      case "/controlpanel":
        return HasPerm("Control Panel");
      case "/oldcollections":
        return HasPerm("Old Collections");
      case "/racesschedule":
        return (
          HasPerm("Full Schedule Access") || HasPerm("Readonly Schedule Access")
        );

      default:
        return false;
    }
  }
  function HasPerm(perm) {
    return user.allowedmodules?.includes(perm);
  }
};

import { React, useContext } from "react";
import { Table, Button, Modal, Popconfirm, notification } from "antd";
import { UserContext } from "../../UserContext";
import {
  GetSchoolInfo,
  GetFormStatus,
  UpdateSchoolStagesComplete,
  UpdateEventEntriesExtension,
  CompleteFormSubmission,
} from "../../APIManager";

import ReadOnlyUploadedFile from "../Shared Components/ReadOnlyUploadedFile";

import { useHistory } from "react-router";
import { useState, useEffect } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
export default function Status() {
  const [schools, SetSchools] = useState([]);
  useEffect(() => {
    FetchDetails();
  }, []);

  function FetchDetails() {
    GetFormStatus().then((res) => {
      SetSchools(res.data);
    });
  }

  const columns = [
    {
      title: "School Name",
      dataIndex: "schoolname",
      key: "schoolname",
    },
    {
      title: "Event Entries",
      dataIndex: "EventEntriesComplete",
      key: "EventEntriesComplete",
      render: (text, record) => {
        if (text === false) {
          return CheckRenderer(text);
        }
        return (
          <Popconfirm
            title="Are you sure to change the Event Entry submission status of this school?"
            onConfirm={() => {
              UpdateSchoolStagesComplete(
                record.schoolid,
                "Event Entries",
                false
              ).then(() => {
                FetchDetails();
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            {CheckRenderer(text)}
          </Popconfirm>
        );
      },
      filters: filters,
      onFilter: (value, record) => record.EventEntriesComplete === value,
    },
    {
      title: "Entries Extension",
      dataIndex: "EventEntriesExtension",
      key: "EventEntriesExtension",
      render: (text, record) => {
        return (
          <Popconfirm
            title="Are you sure to change the Event Entry extension status of this school?"
            onConfirm={() => {
              console.log(record);
              UpdateEventEntriesExtension(
                record.schoolid,
                !record.EventEntriesExtension
              ).then((res) => {
                FetchDetails();
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            {CheckRenderer(text)}
          </Popconfirm>
        );
      },
      filters: filters,
      onFilter: (value, record) => record.EventEntriesComplete === value,
    },
    {
      title: "School Info",
      dataIndex: "SchoolInfoComplete",
      key: "SchoolInfoComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.SchoolInfoComplete === value,
    },
    {
      title: "Team Registrations",
      dataIndex: "TeamRegistrationsComplete",
      key: "TeamRegistrationsComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.TeamRegistrationsComplete === value,
    },
    {
      title: "Team Roles",
      dataIndex: "TeamRolesComplete",
      key: "TeamRolesComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.TeamRolesComplete === value,
    },
    {
      title: "Event Registrations",
      dataIndex: "EventRegistrationsComplete",
      key: "EventRegistrationsComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.EventRegistrationsComplete === value,
    },
    {
      title: "Payment",
      dataIndex: "PaymentComplete",
      key: "PaymentComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.PaymentComplete === value,
    },
    {
      title: "Flag",
      dataIndex: "FlagComplete",
      key: "FlagComplete",
      render: (text, record) => {
        return CheckRenderer(text);
      },
      filters: filters,
      onFilter: (value, record) => record.FlagComplete === value,
    },
    {
      title: "Final Submission",
      dataIndex: "FinalSubmissionComplete",
      key: "FinalSubmissionComplete",
      render: (text, record) => {
        if (text === false) {
          return (
            <Popconfirm
              title="Are you sure to submit the form for this school?"
              onConfirm={() => {
                CompleteFormSubmission(record.schoolid)
                  .then((response) => {
                    // Handle success
                    notification.success({
                      message: "Final Submission Successful",
                      description: response.data.message,
                    });

                    FetchDetails();
                  })
                  .catch((error) => {
                    // Handle error
                    notification.error({
                      message: "Final Submission Failed",
                      description:
                        error.response?.data?.error ||
                        "An unexpected error occurred. Please try again later.",
                    });

                    if (error.response?.data?.checklist) {
                      console.log(
                        "Checklist Status:",
                        error.response.data.checklist
                      );
                    }
                  });
              }}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              {CheckRenderer(text)}
            </Popconfirm>
          );
        }
        return (
          <Popconfirm
            title="Are you sure to un-submit the form for this school?"
            onConfirm={() => {
              UpdateSchoolStagesComplete(
                record.schoolid,
                "Final Submission",
                false
              ).then(() => {
                FetchDetails();
              });
            }}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            {CheckRenderer(text)}
          </Popconfirm>
        );
      },
      filters: filters,
      onFilter: (value, record) => record.FinalSubmissionComplete === value,
    },
  ];

  return (
    <div>
      <h1 style={{ fontSize: "2em" }}>Status</h1>
      <h4>
        A green Team Registrations indicates atleast one rower added
        <br />A green Event Registrations all registered events have been
        assigned rowers
      </h4>
      <div>
        <Table
          dataSource={schools}
          columns={columns}
          pagination={false}
          scroll={{ y: 400 }}
        />
      </div>
    </div>
  );
}

function CheckRenderer(text) {
  if (text === true) {
    return <CheckCircleTwoTone twoToneColor="#52c41a" />;
  } else {
    return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
  }
}

const filters = [
  {
    text: "Completed",
    value: true,
  },
  {
    text: "Incomplete",
    value: false,
  },
];

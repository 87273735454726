import React, { useState, useContext, useEffect } from "react";
import { getLoggedInUser } from "../../APIManager";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  GetEventEntriesReport,
  GetEntriesPerEventReport,
  GetSchoolContactPersonsReport,
  GetSchoolCaptainsReport,
  GetSchoolRowersReport,
  GetSchoolEventEntriesReport,
  GetPontoonSheet,
  GetSchoolRowersKBCReport,
  GetIDCardsSheet,
  GetCoxwainsReport,
  GetStartSheetReport,
  GetResultsSheet,
  GetSchoolsMasterSheetReport,
  GetRowersMasterSheetReport,
  GetEventEntriesMasterSheetReport,
  GetSchedules,
} from "../../APIManager";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Radio,
  Upload,
  Divider,
  Modal,
  Card,
  Col,
  Row,
  List,
  Skeleton,
  Select,
} from "antd";

import { ListOfEvents } from "../../constants";
import { ListOfDays } from "../../constants";

const { Option } = Select;
const ReportsPage = () => {
  const [pontoonModalOpen, SetPontoonModalOpen] = useState(false);
  const [startSheetModalOpen, SetStartSheetModalOpen] = useState(false);
  const [resultsSheetModalOpen, SetResultsSheetModalOpen] = useState(false);

  const [schedules, SetSchedules] = useState([]);

  useEffect(() => {
    GetSchedules().then((res) => {
      SetSchedules(res.data);
    });
  }, []);

  return (
    <div>
      <PontoonSheetModal
        isModalVisible={pontoonModalOpen}
        onClose={() => {
          SetPontoonModalOpen(false);
        }}
      />
      <DaySelectModal
        onClose={() => {
          SetStartSheetModalOpen(false);
        }}
        isModalVisible={startSheetModalOpen}
        onSubmit={(day) => {
          GetStartSheetReport(day);
        }}
        schedules={schedules}
      />
      <DaySelectModal
        onClose={() => {
          SetResultsSheetModalOpen(false);
        }}
        isModalVisible={resultsSheetModalOpen}
        onSubmit={(day) => {
          GetResultsSheet(day);
        }}
        schedules={schedules}
      />
      <br />
      <Row gutter={16}>
        <Col span={12}>
          <List
            size="large"
            header={
              <div>
                {" "}
                <strong>Racing Reports</strong>
              </div>
            }
            bordered
          >
            <List.Item>
              <Button onClick={GetEventEntriesMasterSheetReport} type="link">
                Event Entries Master Sheet
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetEventEntriesReport} type="link">
                Event Entries Report
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetEntriesPerEventReport} type="link">
                Entries Per Event Report
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetSchoolEventEntriesReport} type="link">
                School Event Entries Report
              </Button>
            </List.Item>
            <List.Item>
              <Button
                onClick={() => {
                  SetPontoonModalOpen(true);
                }}
                type="link"
              >
                Pontoon Sheet
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetCoxwainsReport} type="link">
                Coxwains Report
              </Button>
            </List.Item>
            <List.Item>
              <Button
                onClick={() => {
                  SetStartSheetModalOpen(true);
                }}
                type="link"
              >
                Start Sheet
              </Button>
            </List.Item>
            <List.Item>
              <Button
                onClick={() => {
                  SetResultsSheetModalOpen(true);
                }}
                type="link"
              >
                Results Sheet
              </Button>
            </List.Item>
          </List>
        </Col>
        <Col span={12}>
          <List
            size="large"
            header={
              <div>
                {" "}
                <strong>Logistics Reports</strong>
              </div>
            }
            bordered
          >
            <List.Item>
              <Button onClick={GetSchoolsMasterSheetReport} type="link">
                Schools Master Sheet
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetRowersMasterSheetReport} type="link">
                Rowers Master Sheet
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetSchoolContactPersonsReport} type="link">
                School Contact Persons
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetSchoolCaptainsReport} type="link">
                School Captains and Vice Captains
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetSchoolRowersReport} type="link">
                School Rowers Reports
              </Button>
            </List.Item>

            <List.Item>
              <Button onClick={GetSchoolRowersKBCReport} type="link">
                School Rowers with KBC # Report
              </Button>
            </List.Item>
            <List.Item>
              <Button onClick={GetIDCardsSheet} type="link">
                ID Cards Sheet
              </Button>
            </List.Item>
          </List>
        </Col>
      </Row>
    </div>
  );
};

export default ReportsPage;

const PontoonSheetModal = ({ isModalVisible, onClose }) => {
  const [racename, SetRaceName] = useState("");
  return (
    <Modal
      title="Download Pontoon Sheet"
      visible={isModalVisible}
      cloasable
      okText="Download"
      okButtonProps={{ disabled: !racename }}
      onClose={onClose}
      onCancel={onClose}
      onOk={() => {
        GetPontoonSheet(racename);
      }}
    >
      <h1>Select a race</h1>
      <Select
        showSearch
        placeholder="Select a Race"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear
        style={{ width: "50ch" }}
        onChange={(e) => {
          SetRaceName(e);
        }}
      >
        {ListOfEvents.map(function (item, i) {
          return <Option value={item}>{item}</Option>;
        })}
      </Select>
    </Modal>
  );
};

const DaySelectModal = ({ isModalVisible, onClose, onSubmit, schedules }) => {
  const [racename, SetRaceName] = useState("");
  return (
    <Modal
      title="Select Day"
      visible={isModalVisible}
      cloasable
      okText="Download"
      okButtonProps={{ disabled: !racename }}
      onClose={onClose}
      onCancel={onClose}
      onOk={() => {
        onSubmit(racename);
      }}
    >
      <h1>Select a day</h1>
      <Select
        showSearch
        placeholder="Select a day"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        allowClear
        style={{ width: "50ch" }}
        onChange={(e) => {
          SetRaceName(e);
        }}
      >
        {schedules.map(function (item, i) {
          return <Option value={item.Day}>{item.Day}</Option>;
        })}
      </Select>
    </Modal>
  );
};
